@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhai&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer components {
  .no-scroll {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
  }
  /* Chrome, Safari and Opera */
  .no-scroll::-webkit-scrollbar {
    display: none;
  }
}

.adm-radio.adm-radio-checked .adm-radio-icon {
  border-color: #31d3f2 !important;
  background-color: #31d3f2 !important;
}
